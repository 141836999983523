import * as React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { HomepageBanner } from '../components/HomepageBanner'
import { SliceZone } from '@prismicio/react'
import { components } from "../slices";
import { Container, Row, Col } from 'react-bootstrap'
const About = ({ data }) => {

  return (
    <Layout isHomepage>
      <Seo title="Home" />
      <Container>
        <Row style={{ marginTop: '4em' }}>
          <Col md={8}>
            <h1 className='mb-3'>About us</h1>
            <p>
              Saasternatives is an independent blog about the latest and most usefull Saas tools availabe. All the contents are written by us and are our opinion. We do not accept any pre written content from marketing teams. We strive for the best content quality.

              We monetize when companies want to promote their post which is completely written by us independently. Providing trustworthy content to anyone on the internet without any paywall is our objective.
            </p>
            <p>With love,
              <br/>
              Saasternatives Team
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}


export default About